import React from "react"
import {graphql, Link, StaticQuery, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import Meta from "../components/meta"
import circlingArrowsBg from "../images/round_svg.svg";
import employeesImg from "../images/3p.png";
import challengesIcon from "../images/Icon map-wind-surfing.svg";
import feedbackIcon from "../images/Icon material-feedback.svg";
import rewardsIcon from "../images/Icon awesome-award.svg";
import closeIcon from "../images/Icon feather-plus-circle.svg";
import rankingImg from "../images/ranking.png";
import integrationBackgroundImg from "../images/platform bg 1.svg";
import handsHelpingIcon from "../images/Icon awesome-hands-helping.svg";
import brainIcon from "../images/Icon awesome-brain2.svg";
import saveTimeIcon from "../images/Icon ionic-ios-time.svg";
import graphicShadow from "../images/graphic shadow.svg";
import uriGneezyImg from "../images/uri gneezy.png";
import guyHochmanImg from "../images/Guy Hochman.png";
import shaharAyalImg from "../images/Image 43.png";
import empowerIcon from "../images/Icon awesome-hand-peace.svg";
import RequestDemoMiniForm from "../components/request-demo-mini-form";

const CloseButton = () => (
    <a href="javascript:void(0)" className="close_po">
        <img src={closeIcon} alt="close"/>
    </a>
);

const Platform = ({data, location}) => {
    return data && (
        <Layout location={location}>
            <Meta title="Platform"/>
            <div className="banner platform_banner banner_home">
                <div className="wrapper">
                    <div className="banner_content">
                        <h1>It's not the game but the science<br/><strong> that makes us so unique and valuable to
                            our clients</strong></h1>
                        <p>Join our extraordinary journey to recreating the office and home sales environment
                            through our groundbreaking approach to managing the incentive process, motivating
                            employees to achieve excellent results</p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="how_work">
                    <div className={'anchor'} id={'how-it-works'}/>
                    <h6>HOW IT WORKS</h6>
                    <div className="round_info">
                        <div className="round_outer">
                            <div className="round_svg">
                                <img src={circlingArrowsBg} alt="circling arrows"/>
                            </div>
                            <div className="box_wrap">
                                <div className="round_box" id="process5">
                                    <span><img src={employeesImg} alt="employees"/></span>
                                    <h5>Employee</h5>
                                    <h6>Performance</h6>
                                </div>
                                <div className="round_box show" id="process1">
                                    <span><img src={brainIcon} alt="brain"/></span>
                                    <h5>AI engine +</h5>
                                    <h6>Behavioral <br/> model</h6>
                                </div>
                                <div className="round_box" id="process2">
                                    <span><img src={challengesIcon} alt="challenges"/></span>
                                    <h6>Challenges</h6>
                                </div>
                                <div className="round_box" id="process3">
                                    <span><img src={feedbackIcon} alt="feedback"/></span>
                                    <h6>Feedback</h6>
                                </div>
                                <div className="round_box" id="process4">
                                    <span><img src={rewardsIcon} alt="rewards"/></span>
                                    <h6>Rewards</h6>
                                </div>
                            </div>
                            <div className="round_inner">
                                <div className="mo_slider owl-carousel">
                                    <div className="middle_content process5">
                                        <CloseButton/>
                                        <h2>Employee <br/> performance</h2>
                                        <p>Employee performance data is easily pulled from the CRM system</p>
                                    </div>
                                    <div className="middle_content process1 show">
                                        <CloseButton/>
                                        <h2>AI engine</h2>
                                        <p>Identifies milestones in the employee's progress toward their goals,
                                            learns their personal motivational style and creates a personalized
                                            incentivization program for every one of them</p>
                                    </div>
                                    <div className="middle_content process2">
                                        <CloseButton/>
                                        <h2>Challenges</h2>
                                        <p>Provides a challenging and customized experience on a daily basis that
                                            enables each employee to succeed, improve and progress towards achieving
                                            his/her goals</p>
                                    </div>
                                    <div className="middle_content process3">
                                        <CloseButton/>
                                        <h2>Feedback</h2>
                                        <p>Delivers ongoing feedback on progress, effort and opportunities, in an
                                            individual or group format, private or public. Automatic and real-time
                                            with instant notifications to managers</p>
                                    </div>
                                    <div className="middle_content process4">
                                        <CloseButton/>
                                        <h2>Rewards</h2>
                                        <p>A high-frequency reward mechanism that includes awarding points,
                                            accumulating coins and purchasing prizes, allowing all employees to gain
                                            a rewarding experience - not just the leaders</p>
                                        <img src={rankingImg} alt="ranking"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="platform_text">
                    <div className="abo_text cf">
                        <div className={'anchor'} id={'motivational-insights'}/>
                        <div className="right_sec img_content">
                            <div className="explain_img text-right">
                                <div className="mobile_title">
                                    <h4>Motivational Insights</h4>
                                </div>
                                <div className="slider_img owl-carousel">
                                    <div>
                                        <Img fluid={data.platformGraphic1.childImageSharp.fluid}/>
                                    </div>
                                </div>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="left_sec text_content">
                            <h4>Motivational Insights</h4>
                            <p>We are all unique, and as individuals we all have our own way of achieving targets
                                and goals. Understanding motivational styles and preferences that drive each
                                employee is vital to achieving success.</p>
                            <div className="member_team cf">
                                <div className="team_name">
                                    <span><img src={uriGneezyImg} alt="Uri Gneezy"/></span>
                                    <h6>Prof. Uri Gneezy</h6>
                                </div>
                                <div className="team_name">
                                    <span><img src={guyHochmanImg} alt="Guy Hochman"/></span>
                                    <h6>Dr. Hochman Guy</h6>
                                </div>
                                <div className="team_name">
                                    <span><img src={shaharAyalImg} alt="Shahar Ayal"/></span>
                                    <h6>Prof. Shahar Ayal</h6>
                                </div>
                            </div>
                            <Link to={'/book-a-demo'} className="btn">REQUEST A DEMO</Link>
                        </div>
                    </div>
                    <div className="abo_text cf">
                        <div className="left_sec img_content">
                            <div className="explain_img text-left">
                                <div className="mobile_title">
                                    <h4>Amplifying the manager</h4>
                                </div>
                                <Img fluid={data.platformGraphic2.childImageSharp.fluid}/>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="right_sec text_content">
                            <h4>Amplifying the manager</h4>
                            <p>
                                We deliver step-by-step precise tools, guidance and greater resources for today’s
                                number one managerial challenge: How to motivate, engage, incentify and guide
                                employees to meet quotas while maintaining a productive and appealing work
                                environment.
                            </p>
                            <br/><br/><Link to={'/book-a-demo'} className="btn">REQUEST A DEMO</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our_story integration">
                <img src={integrationBackgroundImg} alt="background"/>
                <div className="wrapper">
                    <div className="inner">
                        <div className="col">
                            <h2>INTEGRATION</h2>
                            <p>
                                Enerjoy’s platform seamlessly connects to other solutions in your sales
                                stack.
                            </p>
                            <p>
                                If you can’t see your desired app, please let us know which app you would like us
                                to integrate with.
                            </p>
                            <RequestDemoMiniForm variation={'integration'}/>
                        </div>
                        <div className="col">
                            <div className="co_it">
                                <Img fluid={data.integrationSolutions.childImageSharp.fluid}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper mabager_love">
                <div className={'anchor'} id={'sales-managers-love-us'}/>
                <h2>HERE'S WHY SALES MANAGERS LOVE IT</h2>
                <div className="box_process cf">
                    <div className="box" style={{height: '446px'}}>
                        <span style={{backgroundColor: '#9824C6'}}>
                            <img src={saveTimeIcon} alt="save time"/>
                        </span>
                        <h2>Save Time  &amp; <br/>Resources</h2>
                        <p>
                            With Enerjoy's automatically generated &amp; personalized motivational plan, tailored per
                            employee, you now have the time to create the professional future you want for your
                            organization.
                        </p>
                    </div>
                    <div className="box" style={{height: '446px'}}>
                        <span style={{backgroundColor: '#FDD85B'}}>
                            <img src={handsHelpingIcon} alt="team spirit"/></span>
                        <h2>Team Spirit &amp;<br/>Enriched Relations</h2>
                        <p>
                            How that you've regained your time back, you can spend some quality time with your team.
                            Bring that great team spirit and "go-get-it" approach back and strengthen the bond among
                            team members.
                        </p>
                    </div>
                    <div className="box" style={{height: '446px'}}>
                        <span style={{backgroundColor: '#38EE7D'}}>
                            <img src={empowerIcon} alt="empower"/>
                        </span>
                        <h2>Empower Employees <br/> for Success</h2>
                        <p>
                            Retain talent and boost productivity of average performers, that amount to 80% of total
                            workforce. Thus leveraging all employees' potential to the max!
                        </p>
                    </div>
                </div>
            </div>
            <div className="salse_col">
                <div className="wrapper">
                    <div className="in_sales cf">
                        <div className="text_right">
                            <h3>Want to increase your sales?</h3>
                        </div>
                        <div className="con_mail">
                            <RequestDemoMiniForm/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Platform;

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
`;

export const query = graphql`
    query {
        platformGraphic1: file(relativePath: { eq: "platform graphic 1.png" }) { ...fluidImage }
        platformGraphic2: file(relativePath: { eq: "platform graphic 2.png" }) { ...fluidImage }
        integrationSolutions: file(relativePath: { eq: "co_it.png" }) { ...fluidImage }
    }
`;